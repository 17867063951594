import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    Paper,
    Typography,
    Box,
    Divider,
    Grid,
    Link,
} from '@material-ui/core';
import CopyRight from 'components/CopyRight';
import colors from 'colors';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 60,
        backgroundColor: colors.lightGrey,
    },
    innerContainer: {
        padding: '40px 56px',
        width: 'calc(100% - 120px)',
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]: {
            padding: '28px',
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cardLink: {
        '&:hover': {
            textDecoration: 'none',
        },
    },
}));

const News: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Paper className={classes.innerContainer} elevation={1}>
                <Box textAlign="left">
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <Typography variant="h1" style={{ paddingBottom: 24 }}>
                                Playground News Page
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "right" }} >
                            <Link
                                href="../../.."
                                style={{ textDecoration: 'none' }}
                            >
                                <Typography variant="h1" style={{ paddingBottom: 24 }}>
                                    Home
                                </Typography>

                            </Link>
                        </Grid>
                    </Grid>
                    <div className="content">
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <div className="header">
                                    <Typography variant="h3" style={{ paddingBottom: 24 }}>
                                    Nominations Open For Arif Budiman Malay Language Teacher Award 2021
                                    </Typography>
                                    <Typography variant="subtitle1" style={{ paddingBottom: 24 }}>
                                        Published Date: 29 August 2022 03:47 PM | News, Press Releases
                                    </Typography>
                                </div>
                                <div className="body">
                                    <Typography variant="body1" style={{ paddingBottom: 24 }}>
                                        Students, Educators, Parents, and Members of the Public Are Invited to Nominate Outstanding Malay Language Teachers for the Arif Budiman Malay Language Teacher Award (Agab) 2021. the Award Aims to Recognise Malay Language Teachers in Primary and Secondary Schools, Junior Colleges and Millennia Institute Who Have Made Significant Contributions to the Teaching and Learning of the Malay Language.
                                    </Typography>
                                    <Typography variant="body1" style={{ paddingBottom: 24 }}>
                                        2. Since its inauguration in 2007, AGAB has recognised a total of 64 primary, secondary and pre-university Malay Language teachers. There will be a total of four awards given out under the following categories:
                                    </Typography>
                                    <Typography variant="body1" style={{ paddingBottom: 24 }}>
                                        (i) Primary School and;                                    
                                    </Typography>
                                    <Typography variant="body1" style={{ paddingBottom: 24 }}>
                                        (ii) Secondary School & Pre-university.
                                    </Typography>
                                    <Typography variant="body1" style={{ paddingBottom: 24 }}>
                                        3. Nominees will be selected based on their ability to instil knowledge, skills and interest in the learning of the Malay language, literature and culture in and beyond the classroom, as well as their care and concern for the needs and well-being of their students. Nominees must demonstrate the values of Arif Budiman, a learned person who contributes to society, through their dedication, commitment and exemplary service as a Singapore educator.                                    
                                    </Typography>
                                    <Typography variant="body1" style={{ paddingBottom: 24 }}>
                                        4. Nominations open on 22 February and will close on 9 April 2021. The official nomination form, which will be distributed to all Singapore schools, may also be downloaded from:                                    
                                    </Typography>
                                    <Typography variant="body1" style={{ paddingBottom: 24 }}>
                                        5. AGAB 2021 is jointly organised by the Malay Language Council, Singapore; Malay Language Teachers' Association, Berita Harian, and the Malay Language Learning and Promotion Committee. The AGAB 2021 recipients will be announced in November this year.                            
                                    </Typography>
                                </div>
                            </Grid>               
                        </Grid>
                    </div>
                    <Divider/>
                    <br/>
                    <div className="relateditems">
                        <Typography variant="body1" style={{ paddingBottom: 24 }}>
                            <b/>
                        </Typography>
                        <br/>
                        <a href="##" data-personalise-recommendation=""><p/></a>
                        <a href="##" data-personalise-recommendation=""><p/></a>
                        <a href="##" data-personalise-recommendation=""><p/></a>
                    </div>
                </Box>
            </Paper >
            <CopyRight />
        </div >
    );
};

export default News;
