import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import Spinner, { SpinnerColor, SpinnerSize } from 'components/Spinner';

import Home from 'pages/home';
import Article from 'pages/article';
import News from 'pages/news';
import Moe from 'pages/moe';
import Results from 'pages/results';
import Faq from 'pages/faq';

import './App.css';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Suspense fallback={<Spinner color={SpinnerColor.BLUE} size={SpinnerSize.LARGE} isCenter={true} />}>
                    <Switch>
                        <Route exact={true} path="/">
                            <Home />
                        </Route>
                        <Route exact={true} path="/news">
                            <News />
                        </Route>
                        <Route path="/section/subsection/article">
                            <Article />
                        </Route>
                        <Route path="/moe">
                            <Moe />
                        </Route>
                        <Route path="/results">
                            <Results />
                        </Route>
                        <Route path="/faq">
                            <Faq />
                        </Route>
                    </Switch>
                </Suspense>
            </Router>
        </ThemeProvider>
    );
};

export default App;
