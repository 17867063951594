import { formatISO, add, compareAsc, addSeconds, format as formatDate } from 'date-fns';

const createLocalDateTime = () => {
    return formatISO(new Date()).split('+')[0];
};

const toFormatDate = (date: Date, format: string = 'MM/dd/yyyy') => {
    if (!date) return '';
    return formatDate(date, format);
};

const convertToLocalDateTime = (datetime?: string | null) => {
    if (!datetime) {
        return datetime;
    }
    return formatISO(new Date(datetime)).split('+')[0];
};

const addMinutes = (date: Date, minutes: number) => {
    return add(new Date(date), {
        minutes,
    });
};

const addSecondsToDate = (date: Date, seconds: number) => {
    return addSeconds(new Date(date), seconds);
};

const compareDates = (date1: Date, date2: Date) => {
    const result = compareAsc(new Date(date1), new Date(date2));
    return result < 0 ? false : true;
};

const getTimeLeftFormat = (argSeconds: number) => {
    const minutes = Math.floor(argSeconds / 60); // minutes
    const seconds = argSeconds % 60; // seconds

    const minTitle = minutes > 1 ? 'minutes' : 'minute';
    const secTitle = seconds > 1 ? 'seconds' : 'second';

    if (minutes >= 1) {
        return seconds < 1 ? minutes + ' ' + minTitle : minutes + ' ' + minTitle + ' ' + seconds + ' ' + secTitle;
    } else {
        return seconds + ' ' + secTitle;
    }
};

export default {
    createLocalDateTime,
    convertToLocalDateTime,
    addMinutes,
    compareDates,
    addSecondsToDate,
    getTimeLeftFormat,
    toFormatDate,
};
