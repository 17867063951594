import classnames from 'classnames/bind';
import React, { FC } from 'react';

import styles from './style.module.scss';

const cx = classnames.bind(styles);

export enum SpinnerColor {
    PINK = 'pink',
    WHITE = 'white',
    BLUE = 'blue',
}

export enum SpinnerSize {
    SMALL = 'small',
    LARGE = 'large',
}

interface Props {
    color: SpinnerColor;
    size: SpinnerSize;
    isCenter?: boolean;
}

const Spinner: FC<Props> = ({ color, size, isCenter }) => {
    const spinnerClass = cx({
        spinner: true,
        colorPink: color === SpinnerColor.PINK,
        colorWhite: color === SpinnerColor.WHITE,
        colorBlue: color === SpinnerColor.BLUE,
        sizeSmall: size === SpinnerSize.SMALL,
        sizeLarge: size === SpinnerSize.LARGE,
        center: isCenter,
    });

    return (
        <div data-testid="spinner" className={spinnerClass}>
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    );
};

export default Spinner;
