import { createMuiTheme } from '@material-ui/core/styles';
// import ErrorIcon from './common/images/error.svg';
import colors from 'colors';

declare module '@material-ui/core/styles/overrides' {
    export interface ComponentNameToClassKey {
        MuiAutocomplete: {
            inputRoot: {
                height: number;
            };
        };
    }
}

const theme = createMuiTheme({
    typography: {
        fontFamily: ['Poppins', 'serif'].join(','),
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: colors.blue,
            // dark: will be calculated from palette.primary.main,
            contrastText: colors.white,
        },
        secondary: {
            main: colors.green,
            // dark: will be calculated from palette.secondary.main,
            contrastText: colors.white,
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
        error: {
            main: colors.red,
        },
        text: {
            primary: colors.dark,
            secondary: colors.darkGrey,
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                fontSize: 16,
                lineHeight: '24px',
                color: colors.darkGrey,
                textTransform: 'none',
                '&:hover': { backgroundColor: 'transparent' },
            },
            contained: {
                boxShadow: 'none',
            },
            label: {
                padding: 0,
            },
            textSizeSmall: {
                padding: 0,
                fontSize: 14,
            },
            text: {
                padding: 0,
                minWidth: 'initial',
                fontWeight: 500,
                '&:hover': {
                    color: colors.mediumGrey,
                    backgroundColor: 'transparent',
                },
            },
            textPrimary: {
                color: colors.blue,
                '&:hover': {
                    color: colors.mediumBlue,
                    backgroundColor: 'transparent',
                },
            },
            textSecondary: {
                color: colors.red,
                '&:hover': {
                    color: colors.lightRed,
                    backgroundColor: 'transparent',
                },
            },
            outlinedSizeSmall: {
                padding: '12px 24px',
                fontSize: 14,
                lineHeight: '22px',
            },
            outlinedPrimary: {
                height: 56,
                padding: '16px 32px',
                '&:hover': { backgroundColor: colors.whiteBlue },
                '&$disabled': {
                    background: colors.grey,
                    border: `1px solid ${colors.grey}`,
                    color: colors.white,
                },
            },
            outlinedSecondary: {
                backgroundColor: colors.lightGrey,
                color: colors.dark,
                border: `1px solid ${colors.coolGrey}`,
                padding: '16px 32px',
                '&:hover': {
                    backgroundColor: colors.lighterGrey,
                    border: `1px solid ${colors.coolGrey}`,
                },
                '&$disabled': {
                    background: colors.grey,
                    border: `1px solid ${colors.grey}`,
                    color: colors.white,
                },
            },
            containedPrimary: {
                height: 56,
                padding: '16px 32px',
                '&:hover': { backgroundColor: colors.mediumBlue, boxShadow: 'none' },
                '&$disabled': {
                    backgroundColor: colors.grey,
                    color: colors.white,
                },
            },
            containedSecondary: {
                height: 56,
                padding: '16px 32px',
                backgroundColor: colors.white,
                border: `1px solid ${colors.blue}`,
                color: colors.blue,
                '&:hover': { backgroundColor: colors.whiteBlue, boxShadow: 'none' },
                '&$disabled': {
                    backgroundColor: colors.grey,
                    color: colors.white,
                    borderColor: colors.grey,
                },
            },
        },
        MuiIconButton: {
            root: {
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiMenu: {
            paper: {
                background: colors.dark,
                color: colors.white,
            },
        },
        MuiMenuItem: {
            root: {
                fontSize: 14,
                lineHeight: '21px',
                '&:hover': {
                    backgroundColor: colors.darkGrey,
                },
                padding: '12px 16px',
                paddingTop: 12,
                paddingBottom: 12,
            },
        },
        MuiPaper: {
            elevation1: {
                boxShadow: '2px 2px 8px #E6EAF2',
            },
            elevation2: {
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            },
        },
        MuiAccordion: {
            rounded: {
                borderRadius: 4,
            },
            root: {
                background: `${colors.white} !important`,
                '&.MuiAccordion-root:before': {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiTableCell: {
            root: {
                fontSize: 16,
                lineHeight: '24px',
                borderBottom: `1px solid ${colors.grey}`,
            },
            head: {
                paddingTop: 0,
                color: colors.darkGrey,
            },
        },
        MuiInputBase: {
            root: {
                height: 48,
            },
            input: {
                '&::placeholder': {
                    color: colors.coolGrey,
                    opacity: 1,
                },
            },
        },
        MuiInput: {
            underline: {
                height: 'auto',
                '&&&&:hover:before': {
                    borderBottom: `1px solid ${colors.dark}`,
                },
                '&:after': {
                    borderBottom: `1px solid ${colors.blue}`,
                },
                '&$disabled': {
                    '-webkit-text-fill-color': colors.coolGrey, // Safari
                    color: colors.coolGrey,
                    '&:before': {
                        borderBottomStyle: 'solid',
                        borderBottomColor: colors.grey,
                    },
                    '&&:hover:before': {
                        pointerEvents: 'none',
                        borderBottomColor: colors.grey,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                padding: 0,
            },
            input: {
                padding: '14px 16px',
                fontSize: 16,
                lineHeight: '24px',
            },
        },
        MuiFormHelperText: {
            root: {
                marginLeft: 0,
                marginRight: 0,
                marginTop: 4,
                fontSize: 12,
                lineHeight: '18px',
                display: 'flex',
                alignItems: 'flex-start',
                '&$error::before': {
                    content: "' '",
                    // backgroundImage: `url(${ErrorIcon})`,
                    display: 'inline-block',
                    width: '15px',
                    minWidth: '15px',
                    maxWidth: '15px',
                    height: '15px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '15px 15px',
                    paddingRight: 6,
                    backgroundPosition: 'center left',
                    paddingTop: 1,
                },
            },
            contained: {
                marginLeft: 0,
                marginRight: 0,
                marginTop: 4,
                fontSize: 12,
                lineHeight: '18px',
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: 14,
                lineHeight: '21px',
                color: colors.dark,
                marginBottom: 6,
                '&$focused': {
                    color: colors.dark,
                },
            },
        },
        MuiAutocomplete: {
            inputRoot: {
                height: 'auto',
                minHeight: 56,
            },
        },
        MuiTypography: {
            h1: {
                fontSize: 24,
                lineHeight: '36px',
                fontWeight: 500,
            },
            h2: {
                color: colors.dark,
                fontSize: 20,
                lineHeight: '30px',
                fontWeight: 500,
            },
            body1: {
                // body copy
                fontSize: 16,
                lineHeight: '24px',
            },
            body2: {
                // small text
                fontSize: 14,
                lineHeight: '21px',
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: 14,
                lineHeight: '21px',
                color: colors.dark,
                marginBottom: 8, // 4px for line input
            },
        },
        MuiFormControlLabel: {
            root: {
                color: colors.dark,
            },
        },
        MuiSelect: {
            select: {
                padding: '0 24px 4px 0',
                lineHeight: '24px',
                '&:focus': {
                    backgroundColor: 'transparent',
                },
            },
            outlined: {
                padding: '12px 16px !important',
            },
            icon: {
                color: colors.blue,
            },
        },
        MuiListItem: {
            button: {
                '&:hover': {
                    backgroundColor: colors.darkGrey,
                },
            },
        },
        MuiDialogActions: {
            spacing: {
                padding: '16px 24px',
                '&> :not(:first-child)': {
                    marginLeft: '16px',
                },
            },
        },
        MuiDialog: {
            paperWidthMd: {
                maxWidth: '704px',
            },
        },
        MuiListSubheader: {
            root: {
                lineHeight: 'auto',
                padding: '8px 24px',
                fontWeight: 500,
                color: colors.dark,
            },
            gutters: {
                paddingLeft: 24,
                paddingRight: 24,
            },
        },
        MuiAppBar: {
            root: {
                boxShadow: 'none',
            },
        },
        MuiRadio: {
            root: {
                padding: '0 8px',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
            colorSecondary: {
                color: colors.darkGrey,
                '&$checked': {
                    color: colors.blue,
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiCheckbox: {
            root: {
                padding: '0 8px',
                color: colors.darkGrey,
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
            colorSecondary: {
                color: colors.darkGrey,
                '&$checked': {
                    color: colors.blue,
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: colors.grey,
            },
        },
        MuiSnackbar: {
            root: {
                paddingTop: 65,
            },
        },
    },
});

export default theme;
