import React, { useEffect, FC, useState } from 'react';
import datetime from 'utils/datetime';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'colors';

const useStyles = makeStyles(() => ({
    container: {
        textAlign: 'center',
        marginTop: 32,
    },
    label: {
        fontSize: '14px',
        lineHeight: '21px',
        textAlign: 'center',
        color: colors.darkGrey,
    },
}));

const CopyRight: FC<{}> = () => {
    const classes = useStyles();
    const [lastUpdatedDate, setLastUpdatedDate] = useState<string>('');

    useEffect(() => {
        // const unixTimestamp = process.env.REACT_APP_BUILD_TIME || Date.now() / 1000;
        const unixTimestamp = process.env.REACT_APP_BUILD_TIME;
        if (unixTimestamp) {
            setLastUpdatedDate(datetime.toFormatDate(new Date(+unixTimestamp * 1000), 'dd MMM YYY'));
        }
    }, []);

    const ReportVulnerabilityLink = () => {
        return (
            <a
                style={{ color: colors.darkGrey }}
                href={'https://www.tech.gov.sg/report_vulnerability'}
                rel="noopener noreferrer"
                target={'_blank'}
            >
                <Typography className={classes.label}>Report Vulnerability</Typography>
            </a>
        );
    };

    return (
        <div className={classes.container}>
            <Typography className={classes.label}>
                © 2020 Government of Singapore. Last Updated {lastUpdatedDate}
            </Typography>
            <ReportVulnerabilityLink />
        </div>
    );
};

export default CopyRight;
