export default {
    dark: '#2D3340',
    blue: '#4862E6',
    lightBlue: '#50A7FF',
    mediumBlue: '#647CF3',
    mediumDarkBlue: '#364FCC',
    darkBlue: '#243173',
    lightestRed: '#FCE2DF',
    lightRed: '#E86969',
    red: '#DC2A2A',
    darkRed: '#D9584C',
    green: '#078766',
    yellow: '#C6891E',
    whiteBlue: '#F2F5FF',
    coolGrey: '#B8BECC',
    lighterGrey: '#F7FAFC',
    lightGrey: '#F2F5F7',
    grey: '#E1E3E6',
    backgroundGrey: '#F7F9FF',
    mediumGrey: '#A1A7B3',
    darkGrey: '#8A8F99',
    darkestGrey: '#737780',
    white: '#ffffff',
};
